<template>
  <div
    class="banner-bg mb-6"
    :style="`background-image: url(${rebuildAssetURL(image || '')})`"
    @click.prevent="bannerOnClicked"
  >
    <div
      class="absolute top-[56%] flex w-[286px] -translate-y-1/2 flex-col gap-y-[8px] p-4"
    >
      <div class="lg:mb-6 lg:w-1/2">
        <span class="text-base font-bold text-white lg:text-6xl">{{
          name
        }}</span>
      </div>
      <div class="flex flex-row items-center gap-x-[8px]">
        <div
          class="text-xs font-normal text-white lg:mr-6 lg:text-4xl"
          v-html="description"
        ></div>
        <button
          class="h-[14px] w-[14px] rounded-full bg-white text-[#2A2B2A] lg:h-6 lg:w-6"
        >
          <MdiArrowRight class="text-xs lg:text-xl" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch, ref, onMounted, toRefs } from "vue";
import { storeToRefs } from "pinia";
import { getSectionBanner } from "~/api/home_section/getBanner";
import { rebuildAssetURL } from "~/helpers/url";
import useCityStore from "~/stores/city";
import useClientTypeStore from "~/stores/clientType";
import MdiArrowRight from "~icons/mdi/arrow-right";

const cityStore = useCityStore();
const { selectedCityId } = storeToRefs(cityStore);
const clientTypeStore = useClientTypeStore();
const { isDesktop } = storeToRefs(clientTypeStore);

const props = defineProps({
  endPointApi: {
    type: String,
    default: "",
  },
});

const { endPointApi } = toRefs(props);

const emits = defineEmits<{
  (e: "on-banner-clicked", data: string): void;
}>();

const isLoading = ref(true);
const image = ref<string | null | undefined>(null);
const targetUrl = ref<string>("");
const name = ref<string | null | undefined>("");
const description = ref<string | null | undefined>("");

async function fetchBanner() {
  isLoading.value = true;
  const { success, data } = await getSectionBanner({
    endPointApi: endPointApi.value,
  });

  if (success && data) {
    image.value = isDesktop.value
      ? data.desktopVersions[0].url
      : data.mobileVersions[0].url;
    image.value = rebuildAssetURL(image.value || "");
    targetUrl.value = data.targetUrl || "";
    name.value = data.name;
    description.value = data.description;
    isLoading.value = false;
  }
}

function bannerOnClicked() {
  emits("on-banner-clicked", targetUrl.value);
}

onMounted(() => {
  fetchBanner();
  watch(selectedCityId, () => {
    fetchBanner();
  });
});
</script>
<script lang="ts">
export default {
  name: "HomeBanner",
};
</script>

<style lang="scss" scoped>
.title {
  @apply text-white;
  @screen md {
    font-size: 30px;
  }
  @screen lg {
    font-size: 40px;
  }
}
.banner-bg {
  background: #342828;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 156px;
  @apply relative bg-gray-tertiary;

  @screen lg {
    height: 350px;
  }
}

.sub-title {
  @apply text-white;
  font-size: 10px;
  @screen md {
    font-size: 15px;
  }
  @screen lg {
    font-size: 20px;
  }
}
</style>
