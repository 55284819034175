import {
  string,
  number,
  object,
  nullish,
  array,
  enumType,
  boolean,
  union,
  parse,
} from "valibot";
import { isEmpty, isNil, omitBy } from "lodash-es";
import useHttp from "~/composables/useHttp";
import { useHandleError } from "~/composables/useHandleError";

const dataSchema = object({
  id: nullish(number()),
  name: nullish(string()),
  locations: nullish(array(string())),
  locales: nullish(array(enumType(["en", "th"]))),
  targetUrl: nullish(string()),
  mobileVersions: array(
    object({
      url: nullish(string()),
    })
  ),
  desktopVersions: array(
    object({
      url: nullish(string()),
    })
  ),
  captions: nullish(array(string())),
  active: nullish(boolean()),
  orderNumber: nullish(number()),
  restaurantTagGroupId: nullish(union([number(), string()])),
  mobileRetinaVersions: array(
    object({
      url: nullish(string()),
    })
  ),
  desktopRetinaVersions: array(
    object({
      url: nullish(string()),
    })
  ),
  restaurantTagId: nullish(union([number(), string()])),
  homeSectionId: nullish(number()),
  description: nullish(string()),
  descriptionTh: nullish(string()),
  descriptionEn: nullish(string()),
});

const responseSchema = object({
  data: nullish(dataSchema),
  success: nullish(boolean()),
  message: nullish(string()),
  title: nullish(string()),
  description: nullish(string()),
  footer: nullish(string()),
  tagLine: nullish(string()),
  slug: nullish(string()),
  icon: object({
    url: nullish(string()),
  }),
  clevertapEventName: nullish(string()),
  punchLine: nullish(string()),
  sectionTemplate: nullish(string()),
  sectionImage: nullish(string()),
  active: nullish(boolean()),
});

export async function getSectionBanner({
  endPointApi,
  cityId,
}: {
  endPointApi?: string;
  cityId?: string | number;
}) {
  const errorPath = "api/getSectionBanner";
  const defaultErrorMessage =
    "Oops, something went wrong, failed get section banner";
  try {
    const url = endPointApi || `homes/section_banner.json`;

    const params: { cityId?: string | number } = {
      cityId,
    };
    const parsedParams = omitBy(params, isNil);

    const { data, error } = await useHttp({
      url,
      method: "GET",
      params: parsedParams,
    });

    if (error.message || isEmpty(data)) {
      if (error.shouldReport) {
        useHandleError({
          errorLevel: "error",
          defaultErrorMessage: error.message || defaultErrorMessage,
          errorPath,
        });
      }
      return {
        success: false,
        message: error.message,
        data: null,
      };
    }
    const { data: responseData } = parse(responseSchema, data);
    return {
      success: true,
      message: "",
      data: responseData,
    };
  } catch (error) {
    const message = useHandleError({
      errorPath,
      err: error,
      defaultErrorMessage,
    });
    return {
      success: false,
      message,
      data: null,
    };
  }
}
